import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Select, { components } from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import { CloseIcon } from '../../../assets/SvgIcons';
import { CheckIcon, DropDownIcon } from '../../../iconshere';
import apiClient from '../../../apiClient';
import { SHARE_PROJECT, SHARE_PROJECT_USERS } from '../../../apiClient/apiEndpoints';
import axios from 'axios';
import { debounce } from '../../../utils';

const MultiValueLabel = (props) => {
    return <components.MultiValueLabel>
             <p className='label-tag'>{props.data.name}</p> 
           </components.MultiValueLabel>
  };
  

const ShareProject = ({ showShareModal, closeShareProjectModal, shareProject, usersList }) => {
    
    const [type, setType] = useState("editor");
    const [loading, setLoading] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [projectShareList, setProjectShareList] = useState([]);
    const [optionList, setOptionList] = useState([]);
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);


    useEffect(()=>{
        let filteredUsersList = usersList.filter(user => user.uuid !== userData.user.uuid);
        let userUuids = projectShareList.map(user => user.uuid);
        filteredUsersList = filteredUsersList.filter(user => !userUuids.includes(user.uuid));
    
        let optionsList = filteredUsersList.map((e,i)=>{
            return(
                    {
                        value: e.uuid,
                        name: e.name,
                        label: <div className='label-person'>
                                 { e.avatar?
                                      <img  src= {e.avatar}  alt='profile' />
                                    :
                                    <span className='ini'> {e.name.charAt(0)} </span>
                                 }
                                <span>
                                    <p>{e.name}</p>
                                    <p>{e.email}</p>
                                </span>
                            </div>
                    }
            )
        });

        setOptionList(optionsList);

    },[usersList, projectShareList]);

    const handleTypeChange = (newType) => {
        setType(newType);
    };

    const close = () => {
        closeShareProjectModal();
    }

    const getSharedUsersList = async() =>{
           try {
              let data = await apiClient.get(`${configData.config.API_URL}${SHARE_PROJECT_USERS}/${shareProject.id}`);
              data = data.map(e => ({...e, edit: false}))
              setProjectShareList(data);
           } catch (error) {
            console.log(error);
           }
    }

    useEffect(()=>{
        showShareModal && getSharedUsersList();
    },[showShareModal, shareProject]);

    const handleSelectUsers = (selectedOptions) =>{
        setSelectedUsers(selectedOptions);
    }

    const handleSaveShareProject = async() =>{
        if(loading) return;
        setLoading(true)
        let editUser = projectShareList.filter((e) => e.edit === true ).map((f)=> ({ id: f.uuid, type: f.allocation.type.toLowerCase() }))
        let shareUserList = selectedUsers.map(e=> ({ id: e.value, type: type }));

        let bodyData = {
            data: [...shareUserList, ...editUser]
        }
        
        setSelectedUsers([]);
        try {
          await apiClient.post(`${configData.config.API_URL}${SHARE_PROJECT}/${shareProject.id}`, bodyData);
          getSharedUsersList();
        } catch (error) {
            console.log(error);
        }finally{
            setLoading(false)
        }

    }


    const handleChangeUserType = (uuid, newType) => {
        const updatedUsers = projectShareList.map(user =>
          user.uuid === uuid ? { ...user, edit: true, allocation: { ...user.allocation, type: newType } } : user
        );
        setProjectShareList(updatedUsers);
      };


    const handleDebounceSave = debounce(handleSaveShareProject, 500);

    return (
        <Modal centered show={showShareModal} onHide={close}>
            <Modal.Header className='modalheader'>
                Share "{shareProject.name}" 
                <span onClick={close} ><CloseIcon /></span>
            </Modal.Header>
            <Modal.Body>
                <div className='share-box'>
                    <div className='select-user-group'>
                        <div className='share-user'>
                            <Select
                                options={optionList}
                                components={{MultiValueLabel}}
                                getOptionLabel={(item) => item.label}
                                isMulti
                                name="usersList"
                                value={selectedUsers}
                                placeholder="Select user"
                                filterOption={(option, inputValue) =>
                                    option.data.name.toLowerCase().includes(inputValue.toLowerCase())
                                  }
                                onChange={handleSelectUsers}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    colors: {
                                        ...theme.colors,
                                        primary50: '#ca373c50',
                                        primary25: '#ca373c50',
                                        primary: '#ca373c70',
                                        danger: '#000000',
                                        neutral30: '#00000050'
                                    },
                                })}
                            />
                        </div>

                                {selectedUsers.length > 0 &&
                                    <div className='share-option'>
                                        <Dropdown className='edit-dropdown' >
                                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                                {type === "editor" ? "Editor" : "Viewer"}<DropDownIcon />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {["editor","viewer"].map((role)=>{
                                                    return(
                                                        <Dropdown.Item className={`menu-item ${type === role ? "selected" : ""}`} eventKey={role} onClick={() => handleTypeChange(role)} >
                                                                    {type === role && <CheckIcon />} {role.charAt(0).toUpperCase()+role.slice(1)}
                                                        </Dropdown.Item>
                                                    )
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                }

                    </div>
                    
                    <div className='shared-list'>
                        <h6>Who has access</h6>
                        <div className='people-list'>

                            {projectShareList.map((item, index)=>{
                                const { uuid, avatar, name, email, allocation } = item;
                                let roleType = allocation.type.toUpperCase();
                                const isCurrentUser = uuid === userData.user.uuid;

                                return( 
                                    <div className='individual' key={uuid}>
                                        <div className='profile'>
                                            {avatar ?
                                              <img src={avatar} />
                                              :
                                              <span className='ini'>{name.charAt(0)}</span>
                                            }
                                            <div className='name-email'>
                                                <p> {name} {isCurrentUser && <span>(You)</span> } </p>
                                                <p> {email} </p>
                                            </div>
                                        </div>

                                    <Dropdown className='edit-dropdown'>
                                        <Dropdown.Toggle variant="light" id="dropdown-basic" disabled={isCurrentUser || (roleType === "OWNER") }>
                                             {roleType} 
                                        <DropDownIcon/>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>

                                            {roleType === "OWNER" &&
                                                <Dropdown.Item className={roleType === "OWNER" ?'menu-item selected' : 'menu-item'}  > 
                                                    {roleType === "OWNER" && <CheckIcon/> } Owner 
                                                </Dropdown.Item>
                                            }
                                            
                                            {["EDITOR", "VIEWER"].map((type) => (
                                                <Dropdown.Item
                                                    key={type}
                                                    className={roleType === type ? 'menu-item selected' : 'menu-item'}
                                                    onClick={() => roleType !== type && handleChangeUserType(uuid, type)}
                                                >
                                                    {roleType === type && <CheckIcon />} {type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()}
                                                </Dropdown.Item>
                                            ))}

                                        </Dropdown.Menu>
                                    </Dropdown>
                                    </div>
                            )
                            })}

                        </div>
                    </div>

                </div>
                <Modal.Footer className='footerbtn p-0 '>
                    <button  onClick={close}> Cancel </button>
                       <button className={loading ? "loading save" : ""} onClick={handleDebounceSave} >
                        {loading ? 
                            <svg viewBox="25 25 50 50">
                            <circle r="20" cy="50" cx="50">
                                {""}
                            </circle>
                            </svg>
                            :
                         "Save"
                        }
                         </button>

                </Modal.Footer>
            </Modal.Body>
        </Modal>
    )
}

export default ShareProject
